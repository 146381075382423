/* ComingSoon.css */
.coming-soon-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f8f9fa;
  }
  
  .content {
    text-align: center;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #343a40;
  }
  
  p {
    font-size: 1.2rem;
    color: #6c757d;
  }
  
  /* Optional: Add responsive design */
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
    }
  
    p {
      font-size: 1rem;
    }
  }
  